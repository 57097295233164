import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import {Swiper, SwiperSlide } from "swiper/react";
import {Autoplay} from "swiper";
import "swiper/css/bundle";

import { castBody, h2Linked, h2Unlinked, nl2br, diaryArticle} from "./core/core";


import SideNav from "./components/sideNav";
import {useCastData} from "./service/castData";
import { rootUri, hvnUriRoot, shopInfo } from "./core/defines";

function castSchedule(d){
    /*
    const scTime = (d) => {
        let res = ""
        if(d.sc !==false){ res = `${d.sc_start} ~ ${d.sc_end}` }
        else{ res = "-" }
        return res
    }
    */
    
    return(
        <li className="day">
            {d.sc
                ? <><time>{d.date}</time>{d.sc_start} ~ {d.sc_end}</>
                : <><time>{d.date}</time>-</>
            }
        </li>
    ) 
}

function profContents(profiles){
    const resMaker = (key) => {
        return (<div className="block">
                    <h3>{ profiles[key].title }</h3>
                    <p>{ nl2br(profiles[key].body) }</p>
                </div>)
    }

    if(profiles != undefined){
        return Object.keys(profiles).map((k)=>resMaker(k))
    }

}

function reserveBtn(hvnId){

    let id =""
    let linkTxt= "キャストを予約する"
    if(hvnId){
        id= `?girl_id=${hvnId}`
    }

    return(
        <nav className="wrapper-link">
            <a className="btn-link reserve" 
                href={`${hvnUriRoot}/A6ShopReservation/${id}`} target="_blank" rel="noreferrer noopner">{linkTxt}</a>
        </nav>
    )
}


function linkBtn($url, $txt){
    if($url){
        return(
            <div className="wrapper-link">
                <a className="btn-link link-mov"
                    href={$url}>{$txt}</a>
            </div>
        )
    }    
}

function diaryBlock(diaryData){

    const  diaryArticle = (json)=>{

            let imgPath = "/img/nophoto-diary.jpg"
            if(json.url_img != "null"){ imgPath = json.url_img}

            return (
                <li key={json.url_img} className="content">
                    <a href={json.url_link} target="_blank" rel="noreferrer noopner">
                        <div className="wrappar-img"><img src={imgPath} /></div>
                        <h3>{json.title}</h3>
                    </a>
                </li>)}

    if(diaryData){

        return(
            <section className="block-diary">
                {h2Unlinked("写メ日記")}
                    <ul className="diary-wrappar">
                        { diaryData.map((d)=> diaryArticle(d)) }
                    </ul>
            </section>
        )
    } 
}


function Profile(){
    const {getCastData, castDiaryData, resetCastDiary, hvnId, updateHvnId} = useCastData()
    const {castId} = useParams();
    
    const [castData, setCastData] = useState([])

    
    useEffect(()=>{

        const getData=async()=>{

            const d = await getCastData(castId)

            if(d){
                setCastData(d)
            }

            if(d.url_diary){
                updateHvnId(d.c_id_hvn)
                
            }

            setCastData(d); 
        }

        getData()

        return ()=>{ resetCastDiary() }

    },[castId]);

    // console.log(castData)
    // console.log(hvnId)

    //console.log(hvnDiary)

    return(
        <>
        <div className="container bg-w-alpha">
            <article id="profile" className="main-colmn">
                <section>
                    {h2Linked(castData.c_name, "一覧", "/allcasts")}
                    <div className="txt-center">
                    <Swiper
                        modules={[Autoplay]}
                        loop={true}
                        slidesPerView={1.2}
                        autoplay={true}
                        centeredSlides={true}
                        breakpoints={{ 768:{slidesPerView:3},}}
                    >
                        {castData.c_photo && castData.c_photo.map((val,key) =>{
                            if(val == 1){
                                return <SwiperSlide key={key}><img src={ `${rootUri}/d/p${castData.c_id}_${1+key}.jpg`} /></SwiperSlide>
                            }
                        })}
                    </Swiper>
                        <h3>{`${castData.c_name} (${castData.c_age})`}</h3>
                        {castBody(castData.c_body)}

                    </div>
                </section>
                <section>
                    {h2Unlinked("プロフィール")}
                    <div className="layout-box">
                        { profContents(castData.c_prof) }
                    </div>
                    {h2Unlinked("スケジュール")}
                    <div className="layout-box">
                        <ul className="prof-schedule">
                            {castData.schedule && castData.schedule.map((sc)=> castSchedule(sc))}
                        </ul>
                    </div>
                    <div id="btn-reserve-box">
                        {reserveBtn(castData.c_id_hvn)}
                    </div>
                </section>
                {castDiaryData && castDiaryData.length > 0
                    ? diaryBlock(castDiaryData)
                    : null}
                {castData && linkBtn(castData.url_diary, "写メ日記をみる")}
                {castData && linkBtn(castData.url_mov, "動画をみる")} 

            </article>
            <SideNav />
        </div>
        </>
    )
}
export default Profile;