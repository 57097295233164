
// メタデータ
export const bustArr= ["未設定", "A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L"]
export const rootUri = "https://k-marineblue.com"
export const apiRoot = "https://api.marineblue-g.com/v1"
export const apiShopId = "k_marineblue"

export const diaryApiRoot = "https://api.marineblue-g.com/api"
export const hvnUriRoot = "https://www.cityheaven.net/kumamoto/A4301/A430101/marimburu_kumamoto"

// ヘブンブログパーツ類
export const hvnShopId = "2110023553";

export const hvnApi = {
    Diary:   "https://blogparts.cityheaven.net/widget/?shopId="+ hvnShopId +"&mode=2&type=14&num=5&col=3&color=2&fontcolor=10&btncolor=6&fontsize=15",
    Video:   "https://blogparts.cityheaven.net/widget/?shopId="+ hvnShopId +"&mode=4&type=14&num=5&col=3&color=2&fontcolor=10&btncolor=6&fontsize=15",
    Comment: "https://blogparts.cityheaven.net/widget/?shopId="+ hvnShopId +"&mode=8&type=18&num=5&color=8&fontcolor=10&btncolor=6&fontsize=15",
}

// 店舗情報
export const shopInfo = {
    name:       "マリン熊本本店",
    tel:        "096-245-7168 ",
    openTime:   "8:00~24:00\n (土日祝: 6:00~24:00)",
    zipCode:    "860-0802",
    zipAddress: "熊本県熊本市中央区中央街8-11",
    gmapUri:    "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3353.7263524804725!2d130.70794291167127!3d32.79951438272544!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3540f5feb5f8de0f%3A0x2e4474b2bb3a0ca5!2z44Oe44Oq44Oz54aK5pys5pys5bqX!5e0!3m2!1sja!2sjp!4v1734143134590!5m2!1sja!2sjp",
    alterGmapImg: "/img/map/shop-location_20241214.jpg"
};


export const priceChart = "/img/20240914_price.jpg"
export const priceChart1129 = "/img/20241129_price.webp"

// 20240914 料金表タイマー関数追加 system.js側も参照
export const getPriceChart=(triggerDate)=>{

    const trigger = new Date(triggerDate)
    const now = new Date()

    /* 動作確認する時にCO消して使ってください.
    console.log("現在時刻: ")
    console.log(now)
    console.log("表示差し替え時刻: ")
    console.log(trigger)
    */

    if(now.getTime() > trigger.getTime()){

        return priceChart1129

    }
    return priceChart
}

export const areaName = "熊本"

/* 以下、ページ内表示物など */

export const linkNavs = [
        {name: "ホーム",          path: "/home", blank: false, img: "/img/ico/i-home.png"},
        {name: "出勤表",          path: "/schedule", blank: false, img: "/img/ico/i-schedule.png"},
        {name: "新人一覧",        path: "/newface", blank: false, img: "/img/ico/i-newface.png"},
        {name: "在籍一覧",        path: "/allcasts", blank: false, img: "/img/ico/i-rec-women.png"},
        {name: "料金システム",     path: "/system", blank: false, img: "/img/ico/i-price.png"},
        {name: "アクセス", path: "/access", blank: false, img: "/img/ico/i-map.png"},
        {name: "動画一覧", path: hvnUriRoot + "/shopmovie", blank: true, img: "/img/ico/i-video.png"},
        {name: "写メ日記", path: hvnUriRoot + "/diarylist", blank: true, img: "/img/ico/i-diary.png"},
        {name: "口コミ", path: hvnUriRoot + "/reviews", blank: true, img: "/img/ico/i-review.png"},
        {name: "ネット予約", path: hvnUriRoot + "/A6ShopReservation", blank: true, img: "/img/ico/i-reserve.png"},
        {name: "女子求人", path: "https://www.girlsheaven-job.net/kumamoto/ma-195/sa-324/marimburu_kumamoto/?of=y", blank: true, img: "/img/ico/i-rec-women.png"},
        {name: "男子求人", path: "https://mensheaven.jp/10/marimburu_kumamoto/?of=y", blank: true, img: "/img/ico/i-rec-men.png"},
];


export const slideArr = [
    {
        link: {
            blank: false,
            uri: null },
        img: {
            alt: "お待たせしました！マリン熊本本店、火の国熊本に満を持してグランドオープン！", 
            imgPathSp: "https://api.marineblue-g.com/api/topbanner?t=k_marineblue", },
    },
    {
        link: {
            blank: true,
            uri: "https://men.marineblue-g.com" },
        img: {
            alt: "男女スタッフ大募集！ 店舗スタッフ未経験時給50万円～ 経験者月給80万円～", 
            imgPathSp: "/img/20231019-s-rec.jpg", },
    },
    /*
    {
     link: {
        blank: false,
        uri: null },
     img: {
        alt: "",
        imgPathSp: "/",
        imgPathPc: "", },
    },
    */
];