import React, {createContext, useState, useContext} from "react"
import axios from "axios"

import { apiRoot, apiShopId } from "../core/defines"



const CastDataContext = createContext()
export const useCastData =()=>useContext(CastDataContext)

export default function CastDataProvider({children}){

    // 一覧,top等用
    const [castList, setCastList] = useState([]);
    const [newfaceList, setNewfaceList] = useState([]);
    

    // プロフィールページ用
    const [castDiaryData, setCastDiaryData] = useState([])

    // フッター予約ボタンのキャスト連動用
    const [hvnId, setHvnId]=useState(null)


    const getCastDiary =(hvnDiaryUrl)=>{
        
        axios({
            method: "get",
            url: `${apiRoot}/diary_cast?t=` + hvnDiaryUrl
        })
        .then((res=>{
            // console.log(res)
            if(Array.isArray(res.data)){
                setCastDiaryData(res.data) }
            else{
                setCastDiaryData([])
            }
        }))
    }

    const resetCastDiary=()=>{
        setCastDiaryData([])

        return null
    }

    const getCastData =async(castId)=>{

        const res= await axios({
            method: "get",
            url: `${apiRoot}/profile?s=${apiShopId}&c=${castId}`
        })
            .then((res=>{
                // console.log(res.data)
                
                if(res.data.url_diary.length>0){
                    getCastDiary(res.data.url_diary)
                }

                return(res.data)

            }))
            .catch(e=>{
                return null
            })

        return res
    }

    const getCastList =(mode=null, col=null)=>{

        let target = `${apiRoot}/casts?s=${apiShopId}`

        if(mode=="newface"){ target += "&m=newface" }
        if(col !=null && col>0){ target += `&col=${col}` }
        
        axios({
            method: "get",
            url: target
        })
        .then((res=>{
            // console.log(res)

            if(mode=="newface"){ 
                  setNewfaceList(res.data) }
            else{ setCastList(res.data) }
        }))
    }

    const updateHvnId=(id)=>{
        setHvnId(id)
        return null
    }

    const resetHvnId=()=>{
        setHvnId(null)
        return null
    }


    return(
        <CastDataContext.Provider value={{
            hvnId, updateHvnId, resetHvnId,

            getCastData,
            castDiaryData, resetCastDiary, setCastDiaryData,
            castList, newfaceList, getCastList,
        }}>
            {children}
        </CastDataContext.Provider>
    )
}